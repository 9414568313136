.select {
  background: url("../../assets/other/icon-down.png") no-repeat 99% center;
  color: #121212;
  border: 1px solid #dbdbdb;
  border-radius: 12px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-family: "Space Grotesk", sans-serif;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
  line-height: 27px;
  width: 450px;
}

.option-container {
  position: relative;
  max-height: 200px;
  box-shadow: 9px 8px 24px -1px #bababb;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 2147483647 !important;
  list-style: none;
  padding: 0;
  margin: 0;
  display: block;
  outline: 0;
  padding: 10px 32px 10px 16px;
  border: 1px solid #dbdbdb;
  border-radius: 12px;
  background: white;
  color: #121212;
  font-family: "Space Grotesk", sans-serif;
  transform: none;
  -webkit-transform: translate3d(0, 0, 0);
}

.item {
  position: relative;
  padding: 3px 1em 3px 0.4em;
  font-family: "Space Grotesk", sans-serif;
  font-style: normal;
  font-size: 14px;
  line-height: 16px;
  color: #29333c;
}

.item:hover,
.item:focus {
  color: #007aff;
  cursor: pointer;
}
