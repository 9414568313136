@import '../../index.scss';

.separator {
    height: 3px;
    background-color: #B3D9FF;
    margin: 0.5%;
    margin-top: 0.5%;
    margin-bottom: 0.5%;
    margin-left: -1%;
    margin-right: -1%;
}

.separator-light {
    height: 1px;
    background-color: #B3D9FF;
    margin: 0.5%;
    margin-top: 0.5%;
    margin-bottom: 0.5%;
    margin-left: 0%;
    margin-right: 0%;
}

.separator-main-menu {
    height: 0.5px;
    background-color: #508FD9;
    // width: 70%;
    margin-left: 10px;
    margin-right: 10px;
}

.separator-fancy {
    height: 3px;
    background-color: #B3D9FF;
    margin-top: 0.5%;
    margin-bottom: 0.5%;
}

.tab {
    transition: all 0.4s;
    display: inline-block;
    width: 25%;
    border: 5px solid #D6D6D6;
}

.tab-three {
    transition: all 0.4s;
    display: inline-block;
    width: 33%;
    border: 5px solid #D6D6D6;
}

.progress {
    width: 25%;
    height: 8px;
    transition: all 0.4s;
    display: inline-block;
    background: #D6D6D6;
}

.progress-three {
    width: 33%;
    height: 8px;
    transition: all 0.4s;
    display: inline-block;
    background: #D6D6D6;
}

.active {
    transition: all 0.4s;
    width: 100%;
    height: 8px;
    background: #007FFF;
}

.passive {
    width: 0%;
    background: #007FFF;
}

