.App {
  background-color: transparent;
}

body {
  background-image: url(../src/assets/background/background.svg);
  background-size: cover;
  background-repeat: no-repeat;
  color: #525252;
  //   width: 100vw;
  //   height: 100vh;
  font-family: "Roboto", sans-serif;
  font-style: normal;
}

html,
body {
  overflow-x: hidden;
  overflow-y: hidden;
  margin: 0;
}

::-webkit-scrollbar {
  width: 7;
}
::-webkit-scrollbar-track {
  background-color: rgba(207, 220, 237, 0.3);
}
::-webkit-scrollbar-thumb {
  background-color: #f5faff;
  border-radius: 7;
}

.press,
img,
.press:focus,
img:focus,
svg,
svg:focus {
  outline: 0 !important;
  border: 0 none !important;
}

::-moz-selection {
  /* Code for Firefox */
  color: #525252;
  background: #bddeff;
}

::selection {
  color: #525252;
  background: #bddeff;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: center;
}

.row {
  display: flex;
  align-items: center;
}

.flex-center {
  justify-content: center;
}

.flex-around {
  justify-content: space-around;
}

.flex-between {
  justify-content: space-between;
}

.flex-end {
  justify-content: flex-end;
}

.flex-start {
  justify-content: flex-start;
}

.match-parent-width {
  width: 100%;
}

.match-parent-height {
  height: 100%;
}

.match-screen-height {
  height: 100vh;
}

.small-bottom-distance {
  margin-bottom: 8px;
}

.default-bottom-distance {
  margin-bottom: 12px;
}

.large-bottom-distance {
  margin-bottom: 16px;
}

.xxl-bottom-distance {
  margin-bottom: 32px;
}

.default-row-spacing {
  padding: 8px 0px;
}

.medium-row-spacing {
  padding: 12px 0px;
}

.large-row-spacing {
  padding: 16px 0px;
}

.default-column-spacing {
  padding: 0px 8px;
  box-sizing: border-box;
}

.medium-column-spacing {
  padding: 0px 12px;
  box-sizing: border-box;
}

.large-column-spacing {
  padding: 0px 16px;
  box-sizing: border-box;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.middle-page {
  height: 100vh;
  width: 100vw;
}
