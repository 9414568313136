.circle {
    display: flex;
    width: 40px;
    height: 40px;
    background-color: #BDDEFF;
    border-radius: 50%;
  }
  
.text {
    margin: auto;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;

    color: #525252;
}