.table-row {
  display: table;
  // width: calc((100%) - (24px * 2));
  width: calc((100%) - (10px * 2));
  table-layout: fixed;
  transform: translate3d(0, 0, 0);
  min-height: 100%;
  padding: 0px 0 0px 0;
  z-index: 1000;
  overflow-y: hidden;
  touch-action: none;
}

.column {
  display: table-cell;
  vertical-align: middle;
  text-overflow: ellipsis;
  z-index: 1000;
}

.logo {
  pointer-events: none;
  width: 50px;
  height: 65px;
}

.app-title {
  font-family: "Space Grotesk", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 37px;
  line-height: 43px;
  letter-spacing: 0.5px;
  pointer-events: none;
  margin-left: 10px;
}

.app-title-mobile {
  font-family: "Space Grotesk", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.5px;
  pointer-events: none;
  margin-top: 5px;
  margin-left: 10px;
}

.column {
  display: table-cell;
  vertical-align: middle;
  text-overflow: ellipsis;
  z-index: 1000;
}
