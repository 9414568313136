.modal-icon {
  height: 60px;
  width: 60px;
  margin-right: 10px;
}

.modal-text {
  line-break: auto;
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  color: #29333c;
}

.loading-text {
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  margin-bottom: 16px;
}

.modal-description {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  line-break: auto;
  margin-top: 10px;
  overflow-wrap: break-word;
}

.error {
  margin-top: 5px;
  color: rgb(129, 0, 0);
  font-style: italic;
}
