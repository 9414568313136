.username-cell {
  // max-width: 25vw;
  overflow-wrap: break-word;
}

.table-container {
  max-height: calc(100vh - 210px);

  ::-webkit-scrollbar {
    width: 7;
  }

  ::-webkit-scrollbar-track {
    background-color: rgba(207, 220, 237, 0.3);
  }

  ::-webkit-scrollbar-thumb {
    background-color: #cfdced;
    border-radius: 7;
  }

  border-radius: 10px;
  box-shadow: 5px 10px 10px #ececec;
}
