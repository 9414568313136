// .header-container {
//   padding-left: 25px;
//   width: 100%;
//   height: 90px;
//   padding-top: 15px;
// }

// .header-container-mobile {
//   width: 100%;
//   height: 60px;
//   padding: 10px;
//   z-index: 100001;
// }

.header {
  top: 0;
  left: 0;
  width: 100%;
  background-size: cover;
  display: block;
  position: fixed;
  transform: translate3d(0, 0, 0);
  z-index: 1000;
  touch-action: none;
  background-attachment: fixed;
}
