.input {
    width: 100%; 
    background: #FFFFFF;
    border: 1px solid #ACBBCD;
    border-radius: 5px;
    height: 28.5px;
    box-sizing: border-box;
}

.input:focus {
    outline: none;
    border: 1px solid #B3D9FF;
}

.disabled {
    pointer-events: none;
}